$(function() {
    $('.action-button').on('click', function (event) {
        const button = $(this);
        const menu = $(this).siblings('.action-menu');

        button.addClass('active')
        
        $('.action-button').not(button).removeClass('active');
        $('.action-menu').not(menu).hide();
        menu.toggle();
    
        event.stopPropagation();
    });

    $('.action-menu').on('click', function (event) {
        event.stopPropagation();
    });

    $(document).on('click', function () {
        $('.action-menu').hide();
        $('.action-button').removeClass('active');
    });
});
  
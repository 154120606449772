(function () {
  'use strict';

  // Sticky Header
  // ----------------------------------------
  const header = document.querySelector(".header");

  function stickyHeader() {
    if (window.scrollY > 0) {
      header.classList.add("header-fixed-top");
    } else {
      header.classList.remove("header-fixed-top");
    }
  }
  stickyHeader();
  window.addEventListener("scroll", () => {
    stickyHeader();
  });

  // Navbar Toggler
  // ----------------------------------------
  const navbarToggler = document.querySelector(".navbar-toggler");
  const navbarMenu = document.querySelector(".navbar-wrapper");

  if (navbarToggler) {
    navbarToggler.addEventListener("click", () => {
      navbarToggler.classList.toggle("active");
      navbarMenu.classList.toggle("active");
    });
  }

  // Dropdown Menu Toggler For Mobile
  // ----------------------------------------
  const dropdownMenuToggler = document.querySelectorAll(
    ".nav-dropdown > a"
  );

  dropdownMenuToggler.forEach((toggler) => {
    if (toggler) {
      toggler.addEventListener("click", (e) => {
        e.preventDefault();
        e.target.parentElement.classList.toggle("active");
      });
    }
  });
})();

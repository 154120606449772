$(function() {
    const table = $('#dataTable table')
    const dataTableWrapper = $('#dataTableWrapper').fadeIn(1000);

    if (!table.length || !dataTableWrapper.length) return

    const rows = Array.from(table.find('tbody tr')).map(row =>
        Array.from($(row).find('td')).map(cell => cell)
    )

    const headers = $.map(table.find('thead th'), function(header, index) {
        const parser = new DOMParser()

        let sorting = false

        switch($(header).data('sorting')) {
            case "initial":
                sorting = {
                    compare: (a, b) => {
                        let keyString1 = parser.parseFromString(a.props.content, 'text/html')
                        let key1 = keyString1.querySelector('div').getAttribute('data-sort-key')

                        let keyString2 = parser.parseFromString(b.props.content, 'text/html')
                        let key2 = keyString2.querySelector('div').getAttribute('data-sort-key')
                        
                        return key2 - key1
                    }
                }
                break
            case "number":
                sorting = {
                    compare: (a, b) => {
                        let number1 = Number(parser.parseFromString(a.props.content, 'text/html').body.textContent || '')
                        let number2 = Number(parser.parseFromString(b.props.content, 'text/html').body.textContent || '')

                        return number2 - number1
                    }
                }
                break
            case "text":
                sorting = {
                    compare: (a, b) => {
                        let text1 = parser.parseFromString(a.props.content, 'text/html').body.textContent || ''
                        let text2 = parser.parseFromString(b.props.content, 'text/html').body.textContent || ''
                        
                        return text1.localeCompare(text2)
                    }
                }
                break
            case "date":
                sorting = {
                    compare: (a, b) => {
                        let dateString1 = parser.parseFromString(a.props.content, 'text/html')
                        let date1 = dateString1.querySelector('div') ? dateString1.querySelector('div').getAttribute('data-sort-key') : '1970-01-01'

                        let dateString2 = parser.parseFromString(b.props.content, 'text/html')
                        let date2 = dateString2.querySelector('div') ? dateString2.querySelector('div').getAttribute('data-sort-key') : '1970-01-01'
                        
                        return date1.localeCompare(date2)
                    }
                }
                break
        }

        return{
            id: index,
            name: $(header).data('hide-label') ? '' : header.textContent,
            sort: sorting
        }
    })

    let pagination = {
        limit: table.data('pagination-limit') ?? 20
    }

    new gridjs.Grid({
        columns: headers,
        data: rows,
        pagination: pagination.limit == 0 ? false : pagination,
        fixedHeader: true,
        search: true,
        language: {
            search: {
                placeholder: "Durchsuchen",
            },
            sort: {
                sortAsc: "Aufsteigend sortieren",
                sortDesc: "Absteigend sortieren"
            },
            pagination: {
                previous: "Zurück",
                next: "Weiter",
                showing: "Ergebnis",
                of:"von",
                to:"bis",
                results: () => null,
            },
            loading: "Laden",
            noRecordsFound: "Keine Ergebnisse",
            error: "Es kam leider zu einem Fehler - bitte lade die Seite nochmal"
        }
    })
    .render(dataTableWrapper.get(0))
    .on('rowClick', (...args) => {
        const firstColumn = new DOMParser().parseFromString(args[1]._cells[0].data.props.content, 'text/html')
        const link = firstColumn.querySelector('a')

        if (link && link.href && args[0].target.tagName !== 'BUTTON' && args[0].target.tagName !== 'A') {
            //window.location.href = link.href
        }
    });

    waitForElement('.gridjs-td').then(() => {
        $('.gridjs-table tbody tr')
            .find('[data-next-sunday]')
            .closest('tr')
            .addClass('highlight-row')
            .attr('data-highlight', 'highlight');
    });
})

function waitForElement(selector) {
    return new Promise(resolve => {
        if ($(selector).length) {
            return resolve($(selector))
        }

        const observer = new MutationObserver(() => {
            if ($(selector).length) {
                observer.disconnect()
                resolve($(selector))
            }
        })

        observer.observe(document.body, {
            childList: true,
            subtree: true
        })
    })
}
